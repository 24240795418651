.bg-transparent {
  background-color: transparent;
}

.nav-link-text {
  color: white;
  font-weight: 700 !important;
}

@media screen and (max-width: 991px) {
  .collapse.show {
    background-color: #0a0a0a !important;
  }
  .bg-transparent {
    background-color: #0a0a0a;
  }
}

@media screen and (max-width: 991px) {
  .collapse.show {
    background-color: #0a0a0a !important;
  }
  .bg-transparent {
    background-color: #0a0a0a !important;
  }
  .logo-hero-img {
    max-width: 80%;

  }
  .platform-link-box {
    height: 60px !important;
    padding-top: 15px !important;
  }
  .music-video {
    margin-top: 16px;
  }
}

.apple-icon {
  height: 30px;
  width: 30px;
  margin-top: "-5px";
}

.platform-link-box {
  height: 100px;
  border: 2px solid rgba(252, 252, 252, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1), 0 0 40px rgba(0, 0, 0, 0.1),
    0 0 60px rgba(0, 0, 0, 0.1), 0 0 80px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 10px;
  padding-top: 35px;
  text-align: center;
  white-space: nowrap;
}
