.gif{
    background-image: url(../assets/gif.gif);
    height: 100vh;
    width: 100vw;
    background-size: 100% 100%;
    position: absolute;
    z-index: 12;
    top: 0;
    left: 0;


}
.bg{
    background-color: rgba(237, 237, 237, 0.5);
    width: 100vw;
    height: 100vh;
}
.mac{
    width: 100vw;
    height: 100vh;
    background-image: url(../assets/mac.png);
    background-size: 110%110%;
    background-position: center;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;

}

.stick-content{
    width: 100%;
    height: 100%;
             animation: 3.4s linear 0s 1 normal none running turn-on;
}
@keyframes turn-on {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.tube-shape{
   
    color: white;
    text-align: center;
    overflow: hidden;
    height: 100%;
    width: 100%;
}
.home-content{
 
    background-size: cover;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.home-container {
    margin: auto;
    background: #e6e2e2;
    width: 55vw;
    height: 57vh;
    border: 2px solid white;
    /* border-radius: 7px; */
    min-height: 300px;
    min-width: 465px;
}
.home-video-container {
    position: relative;
    width: 0;
    height: 0;
    margin-left: 100%;

}
.home-navbar {
    background: navy;
    /* border: 3px solid #979797; */
    text-align: left;
    color: black;
    position: relative;
    margin: 2px 2px 0;
    padding: 7px 0 5px 1%;
}
@import url(https://db.onlinewebfonts.com/c/1a243397695904f6f56e490ffd77a9cb?family=ChicagoFLF);
font-face {
    font-family: "ChicagoFLF";
    src: url("https://db.onlinewebfonts.com/t/1a243397695904f6f56e490ffd77a9cb.eot");
    src: url("https://db.onlinewebfonts.com/t/1a243397695904f6f56e490ffd77a9cb.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/1a243397695904f6f56e490ffd77a9cb.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/1a243397695904f6f56e490ffd77a9cb.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/1a243397695904f6f56e490ffd77a9cb.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/1a243397695904f6f56e490ffd77a9cb.svg#ChicagoFLF")format("svg");

}

*, *:after, *:before {
	box-sizing: border-box;
}




.mac-window {
	background: white;
	color: black;
	border: 1px solid black;
	width: 600px;
	border-radius: 1px;
	box-shadow: 1px 1px 0 black;
	font-family: "ChicagoFLF";
    height: 250px;
  
}
.mac-window-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
}
@media screen and (max-width: 769px) {
    .mac-window {
        width: 300px;
    }
    
}

.mac-window .title-bar {
	text-align: center;
	border-bottom: 1px solid black;
	width: 100%;
	position: relative;
    height: 20px;
}

.mac-window .title-bar .bars-container {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: column nowrap;
	flex-flow: column nowrap;
	justify-content: center;
	margin: 3px 1px 2px 1px; 
	position: relative;
}

.mac-window .title-bar .bars-container .bar {
	margin-bottom: 1px;
	border-bottom: 1px solid black;
	width: 100%;
}

.mac-window .title-bar .close {
	width: 13px;
	height: 13px;
	position: absolute;
	top: -1px;
	left: 7px;
	background: white;
	padding: 1px;
	z-index: 2;
}

.mac-window .title-bar .close .inner {
width: 100%;
height: 100%;
border: 1px solid black;
}

.mac-window .title-bar .title {
	display: inline;
	position: absolute;
	top: -3px;
	left: 50%;
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
	box-sizing: none;
	padding: 0 6px;
	background: white;
	font-size: .8em;
	height: 100%;
	white-space: nowrap;
	overflow: hidden;
}

.mac-window p {
	padding: 0 10px;
}
.folder{
cursor: pointer;
height: 75px;
width: 75px;
}