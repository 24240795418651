#apple-s::before {
  content: " Apple Music";
  color: white;
  font-size: 20px;
  font-weight: 700;
}

@media screen and (max-width: 991px) {
  #apple-s::before {
    content: "" !important;
    font-size: 0px !important;
  }
}
