#soundcloud-s::before {
  content: "SoundCloud";
  color: white;
  font-size: 20px;
  font-weight: 700;
}

@media screen and (max-width: 991px) {
  #soundcloud-s::before {
    content: "" !important;
    font-size: 0px !important;
  }
}
